import './style/PatientRecordHeaderForm.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Col, DatePicker, Form, Row, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import AxiosClient from '../../apis/api/axiosClient';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import BaseForm, { BaseFormRef } from '../../components/FormFields/BaseForm';
import ComboBox, { IComboBox, ILastAddressCombobox } from '../../components/FormFields/ComboBox';
import ComboBoxWithSwitch from '../../components/FormFields/ComboBoxWithSwitch';
import NumberField from '../../components/FormFields/NumberField';
import RadioField from '../../components/FormFields/RadioField';
import TextField from '../../components/FormFields/TextField';
import { optionGender } from '../../constants/OptionsCombo';
import { pathDateTime, pathMedicine } from '../../constants/SvgIcon';
import { phoneRegExp } from '../../constants/YupContants';
import { ApiResponse } from '../../types/api.type';
import { PatientRecordHeaderDto } from '../../types/MedicalExamination/PatientRecordHeaderDto';
import { ApiUtil, BASE_API_PATH } from '../../utils/ApiUtil';
import { startVital } from '../MeasuringVitalSign/api/constants';

const { CheckableTag } = Tag;

interface IHeaderProps
{
    lastAddressCombobox: ILastAddressCombobox | null;
    listAddonId: string[]
    state: object;
    setState: any;
    // isUsingAddon: boolean;
    // setUsingAddon: React.Dispatch<React.SetStateAction<boolean>>;
    loadOptionsDistrict: (value: string) => Promise<void>;
    optionsProvince: IComboBox[];
    optionsDistrict: IComboBox[];
    optionsDesignMedicalExam: IComboBox[];
    options?: IComboBox[];
    formRefFilter: React.RefObject<BaseFormRef>;
    onHandleStartExam: (value: number, formValue?: object) => Promise<void>;
    handleStartSession: (designationExamIds: number[], isMedicineOnly?: boolean) => Promise<void>;
    handleAddonService: (formValue?: object) => Promise<void>;
    valueSelected?: PatientRecordHeaderDto | undefined;
    selectedTags: string[]
    setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>
    onFinishForm: any;
}
const schema = yup.object().shape({
    fullName: yup.string().required('Vui lòng nhập thông tin!'),
    phone: yup.string().required('Vui lòng nhập thông tin!').min(8, 'Số điện thoại lớn hơn 8 số.').max(12, 'Số điện thoại ít hơn 12 số').matches(phoneRegExp, 'Định dạng không hợp lệ'),
    reason: yup.string().required('Vui lòng nhập thông tin!').nullable(),
    // designationMedicalExamId: yup.string().required('Vui lòng nhập thông tin!').nullable()
});

const PatientRecordHeaderForm = (props: IHeaderProps) =>
{
    const { lastAddressCombobox, listAddonId, selectedTags, setSelectedTags, handleStartSession, state, setState, formRefFilter, optionsProvince, optionsDistrict, loadOptionsDistrict, optionsDesignMedicalExam } = props; //
    // const [numberFlow, setNumberFlow] = useState<number>(1);

    const numberFlow = useRef<number>(1);
    const handleSelectTag = (tag: string, checked: boolean) =>
    {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        setSelectedTags(nextSelectedTags);
    };


    useEffect(() =>
    {
        getLastUsedProvinceAndDistrict();
    }, []);

    const iconMedicine: React.ReactNode = <svg
        fill="none"
        className="w-4 h-4 sm:w-6 sm:h-6 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 14">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={pathMedicine}
            fill="#fff" />
    </svg>;

    // Lấy giá trị của lần cuối sử dụng combobox province và district
    const getLastUsedProvinceAndDistrict = async () =>
    {
        // Lấy ra provinceId và districtId được user sử dụng lần cuối
        const provinceId = lastAddressCombobox?.provinceId;
        const districtId = lastAddressCombobox?.districtId;


        if (provinceId)
        {
            loadOptionsDistrict(provinceId);
        }

        const dataForm = formRefFilter?.current?.getValues();

        // Selected province và district
        formRefFilter.current?.setValues({
            ...dataForm, provinceId: provinceId, districtId: districtId
        });
    };

    const onChangeDistrictId = (id: string | null) =>
    {
        const dataForm = formRefFilter?.current?.getValues();
        formRefFilter.current?.setValues({
            ...dataForm, districtId: id
        });
    };

    const onChangeProvince = (value: string) =>
    {
        // Lấy dữ liệu district theo province mới chọn
        loadOptionsDistrict(value);

        // Mặc định là chưa chọn district nào
        onChangeDistrictId(null);
    };


    // const onChangeDictrict = (value: string) =>
    // {
    //     const dataForm = formRefFilter?.current?.getValues();
    //     onChangeProvinceId(value);
    //     loadOptionsDistrict(value);
    //     formRefFilter.current?.setValues({
    //         ...dataForm, districtId: null
    //     });
    // };
    const suffixDateTimeFilter = () =>
    {
        return <svg
            fill="none"
            className="w-3 h-3 sm:w-4 sm:h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18">
            <path
                d={pathDateTime}
                fill="#65676B" />
        </svg>;
    };

    const startVitalSign = async (body: any) =>
    {
        await AxiosClient.post<ApiResponse<any>>(BASE_API_PATH + startVital, body, { withCredentials: true });
    };

    return (
        <div className="mt-3 justify-between">
            <div>
                <BaseForm
                    ref={formRefFilter}
                    onSubmit={async () =>
                    {

                        localStorage.setItem('patientInfoValue', JSON.stringify(formRefFilter.current?.getValues()));
                        if (numberFlow.current === 1)
                        {
                            if (selectedTags?.length === 0)
                            {
                                ApiUtil.ToastError('Vui lòng chọn ít nhất 1 chỉ định khám');
                            }
                            else
                            {
                                handleStartSession(selectedTags?.map(item => Number(item)));
                            }
                        } else
                        {
                            if (selectedTags?.length !== 1)
                            {
                                ApiUtil.ToastError('Vui lòng chỉ chọn 1 chỉ định khám');
                            }
                            else if (listAddonId.includes(selectedTags[0]))
                            {
                                ApiUtil.ToastError('Vui lòng chỉ chọn 1 chỉ định khám chính');
                            }
                            else
                            {
                                handleStartSession(selectedTags?.map(item => Number(item)), true);
                            }
                        }
                    }}
                    style={{
                        width: '100%'
                    }}
                    defaultValues={{
                    }}
                    resolver={yupResolver(schema)} >

                    <Row
                        className="flex"
                        gutter={10}
                        style={{
                            width: '100%'
                        }}>
                        <Col
                            span={5}
                            className="">
                            <Form.Item className='patient-input'>
                                <TextField
                                    indexLabel='1'
                                    noUpperCaseLabel={true}
                                    requiredError={true}
                                    name={'fullName'}
                                    labelClass='text-xs font-bold'
                                    label={'Họ tên bệnh nhân'} />
                            </Form.Item>
                        </Col>
                        <Col
                            span={4}
                            className="">
                            <Form.Item className='patient-input'>
                                <NumberField
                                    indexLabel='2'
                                    noUpperCaseLabel={true}
                                    requiredError={true}
                                    name={'phone'}
                                    label={'Số điện thoại'} />
                            </Form.Item>
                        </Col>
                        <Col
                            span={3}
                            className="text-xs ">
                            <Form.Item
                                className='text-xs patient-input'
                            >
                                <Controller
                                    name={'yearOfBirth'}
                                    render={({ field }) =>
                                    {
                                        return <div
                                            className='flex flex-col gap-y-2.5 pt-0.5'>
                                            <span
                                                style={{
                                                    width: 250,
                                                    font: 'Roboto',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                                className='text-xs block tracking-wide text-gray-700 font-bold'>3. Năm sinh</span>
                                            <DatePicker
                                                {...field}
                                                placeholder='Chọn năm sinh'
                                                suffixIcon={suffixDateTimeFilter()}
                                                disabledDate={(current) =>
                                                {
                                                    return current && current > moment().endOf('day');
                                                }}
                                                style={{
                                                    width: '100%', borderRadius: '7px', marginTop: '-2px'
                                                }}
                                                picker="year"
                                            />
                                        </div>;
                                    }
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item className='patient-input'>
                                <RadioField
                                    indexLabel='4'
                                    noUpperCaseLabel={true}
                                    name={'genderValue'}
                                    comboValue={optionGender}
                                    label={'Giới tính'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        className="flex"
                        gutter={10}
                        style={{
                            width: '100%'
                        }}>
                        <Col
                            span={5}>
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" style={{ marginLeft: 12 }}>5. Địa chỉ bệnh nhân</label>
                            <Form.Item className='patient-input'>
                                <ComboBox
                                    noUpperCaseLabel={true}
                                    noShowLabel={true}
                                    onChange={(value) => { onChangeProvince(value); }}
                                    comboValue={optionsProvince}
                                    name={'provinceId'}
                                    label={'Tỉnh/TP'}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            span={4}>
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" style={{ marginLeft: 12, visibility: 'hidden' }}>Quận huyện</label>
                            <Form.Item className='patient-input'>
                                <ComboBox
                                    noUpperCaseLabel={true}
                                    noShowLabel={true}
                                    comboValue={optionsDistrict}
                                    onChange={(value) => { onChangeDistrictId(value); }}
                                    name={'districtId'}
                                    label={'Quận/Huyện'} />
                            </Form.Item>
                        </Col>
                        <Col
                            span={6}>
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" style={{ marginLeft: 12, visibility: 'hidden' }}>Địa chỉ</label>
                            <Form.Item className='patient-input'>
                                <TextField
                                    noUpperCaseLabel={true}
                                    noShowLabel={true}
                                    label={'Số nhà, tên đường'}
                                    name={'location'} />
                            </Form.Item>
                        </Col>

                        <Col
                            span={9}>
                            <Form.Item className='patient-input'>
                                <TextField
                                    noUpperCaseLabel={true}
                                    indexLabel='6'
                                    requiredError={true}
                                    name={'reason'}
                                    label={'Lý do khám'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        className="flex"
                        gutter={10}
                        style={{
                            width: '100%',
                        }}>
                        <Col
                            span={12}
                            className="flex flex-col justify-end">
                            <Form.Item className='patient-input'>
                                <TextField
                                    noUpperCaseLabel={true}
                                    indexLabel='7'
                                    name={'symptom'}
                                    label={'Triệu chứng'} />
                            </Form.Item>
                        </Col>
                        <Col
                            span={12}
                            className="flex flex-col justify-end">
                            <Form.Item className='patient-input'>
                                <TextField
                                    noUpperCaseLabel={true}
                                    indexLabel='8'
                                    name={'note'}
                                    label={'Ghi chú'} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='p-4 pt-1 flex flex-wrap gap-y-2'>
                        {optionsDesignMedicalExam.map((item, index) =>
                            <CheckableTag
                                key={index}
                                checked={selectedTags.indexOf(item.value) > -1}
                                onChange={checked => handleSelectTag(item.value, checked)}
                            >
                                <Tooltip title={item.label}>
                                    <div className='truncate'>
                                        {item.label}
                                    </div>
                                </Tooltip>
                            </CheckableTag>
                        )}
                    </div>

                    <hr
                        style={{
                            marginLeft: '20px', marginRight: '20px', marginTop: '-7px'
                        }} />

                    <Row
                        className="flex "
                        gutter={30}
                        style={{
                            width: '100%', height: '50px', justifyContent: 'center', alignContent: 'center', alignItems: 'center'
                        }}>
                        <div className="footer flex">
                            <ButtonBase
                                style={{
                                    width: 154, height: 40, justifyContent: 'center'
                                }}
                                onClick={async () =>
                                {
                                    const data = formRefFilter.current?.getValues();
                                    if (!data?.fullName || !data?.phone)
                                    {
                                        ApiUtil.ToastError('Vui lòng chọn bệnh nhân để đo');
                                    }
                                    else
                                    {
                                        const date = new Date(data.yearOfBirth);
                                        const year = date.getFullYear();
                                        await startVitalSign({
                                            ...data,
                                            id: null,
                                            yearOfBirth: year.toString(),
                                            designationMedicalExamId: data.medicalExamId,
                                        });
                                        setState({ ...state, isMeasuring: true });

                                    }
                                }}
                                buttonName={'ĐO CHỈ SỐ'}
                                backgroudColorCustom={'#2C999C'}
                                buttonType={'button_custom'}
                            />
                            <ButtonBase
                                className='ml-2'
                                name='check1'
                                style={{
                                    width: 154, height: 40, justifyContent: 'center'
                                }}
                                onClick={() => { numberFlow.current = 1; }}
                                buttonName={'KHÁM BỆNH'}
                                buttonType="play_exam"
                                htmlType="submit" />
                            <ButtonBase
                                className='ml-2'
                                onClick={() => { numberFlow.current = 4; }}
                                style={{
                                    width: 180, height: 40, justifyContent: 'center'
                                }}
                                buttonName={'TẠO ĐƠN THUỐC'}
                                iconCustom={iconMedicine}
                                backgroudColorCustom='#E78429'
                                buttonType="button_custom"
                                htmlType="submit" />
                        </div>
                    </Row>
                </BaseForm>
            </div>
        </div>
    );
};

export default PatientRecordHeaderForm; 