
import './style/layout-app.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Button, Menu } from 'antd';
import { Avatar, Layout } from 'antd/lib';
import axios from 'axios';
import _ from 'lodash';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useEffect, useRef, useState } from 'react';
import
{
    BrowserRouter as Router,
    Link,
    Route,
    Switch,
    useLocation
} from 'react-router-dom';

import AxiosClient, { Axios } from '../apis/api/axiosClient';
import { authAction } from '../features/auth/AuthSlice';
import AutoSendNotificationView from '../features/AutoSendNotification/pages/AutoSendNotificationView';
import ClinicSettingView from '../features/Clinic/pages/ClinicSettingView';
import ClinicView from '../features/Clinic/pages/ClinicView';
import ClinicServiceView from '../features/ClinicService/pages/ClinicServiceView';
import ClinicVisitsView from '../features/ClinicVisits/pages/ClinicVisitsView';
import DesignationMedicalExamView from '../features/DesignMedicalExam/pages/DesignationMedicalExamView';
import DiseaseAnalysisView from '../features/DiseaseAnalysis/pages/DiseaseAnalysisView';
import ExaminationHistory from '../features/ExaminationHistoryManagement/pages/ExaminationHistory';
import { getInfoPay, loadInfomationClinic } from '../features/Infomation/api/constants';
import { clinicAction, selectShowStorageWarning } from '../features/Infomation/ClinicSlice';
import MedicalExamPicturesView from '../features/MedicalExamPictures/pages/MedicalExamPicturesView';
import MedicalExamTemplateView from '../features/MedicalExamTemplate/pages/MedicalExamTemplateView';
import MedicineView from '../features/Medicine/component/MedicineView';
import MedicineGroupView from '../features/MedicineGroup/component/MedicineGroupView';
import MessageView from '../features/Message/component/MessageView';
import PartnerList from '../features/PartnerManagement/pages/PartnerList';
import PatientCareView from '../features/PatientCare/pages/PatientCareView';
import PatientRecordView from '../features/PatientRecord/page/PatientRecordView';
import PatientList from '../features/PatientsManagement/pages/PatientsList';
import PatientWaitingView from '../features/PatientWaiting/pages/PatientWaitingView';
import StatisticsDashboardAdminView from '../features/StatictisDashboardAdmin/pages/StatisticsDashboardAdminView';
import { getStatisticInSidebar } from '../features/StatisticsDashboard/api/constants';
import StatisticsDashboardView from '../features/StatisticsDashboard/pages/StatisticsDashboardView';
import { loadCheckLogin, loadDeviceToken, loadInfoUser } from '../features/UserAccountSetting/api/urlUserAccountConstant';
import UserAccountSettingView from '../features/UserAccountSetting/pages/UserAccountSettingView';
import { onMessageListener, requestForToken } from '../firebase';
import { useAppDispatch, useAppSelector } from '../hooks/hook';
import useMergeState from '../hooks/useMergeState';
import ChangePasswordForm from '../module/ChangePassword/ChangePasswordForm';
import { ApiResponse } from '../types/api.type';
import { Clinic } from '../types/Clinic';
import { userAccountLogin } from '../types/config/userAccountLogin';
import { GetInfoPay } from '../types/PaymentClinic/GetInfoPay';
import { ApiUtil, BASE_API_PATH } from '../utils/ApiUtil';
import MedicalExaminationView from './../module/MedicalExamination/MedicalExaminationView';
import CustomModal, { ModalRef } from './CustomModal/CustomModal';
import HeaderADV from './HeaderADV';
const { Header, Content, Sider } = Layout;

type Statistics = {
    examinedValue: number;
    examinedDifferenceValue: number;
    pendingValue: number;
    pendingDifferenceValue: number;
}

interface IState
{
    defaultSelectedKeysLeft: string;
    defaultSelectedKeysTop: string;
    locationState: string;
    fullName: string;
    userName: string;
    roles: string[];
    collapsed: boolean;
    infoClinic: Clinic;
    loading: boolean;
    statistics: Statistics;
}

const LayoutApp = () =>
{

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false
                    }
                }
            })
    );

    const showStorageWarning = useAppSelector(selectShowStorageWarning);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [state, setState] = useMergeState<IState>({
        defaultSelectedKeysLeft: '',
        defaultSelectedKeysTop: '',
        locationState: location.pathname,
        fullName: '',
        userName: '',
        roles: [],
        collapsed: false,
        infoClinic: {},
        loading: true,
        statistics: {
            examinedValue: 0,
            examinedDifferenceValue: 0,
            pendingValue: 0,
            pendingDifferenceValue: 0
        }
    });

    const modalRef = useRef<ModalRef>(null);
    const [deviceToken, setDeviceToken] = useState<string>();
    const [infoPay, setInfoPay] = useState<GetInfoPay | null>(null);

    useEffect(() =>
    {
        onCheckLogin();
    }, []);

    useEffect(() =>
    {
        loadTokenDevice();
    }, [deviceToken]);

    const [intervalId, setIntervalId] = useState<any>(null);

    useEffect(() =>
    {
        if (showStorageWarning)
        {
            ApiUtil.ToastWarning('Dung lượng S3 vượt quá 5GB');

            const interval = setInterval(() =>
            {
                ApiUtil.ToastWarning('Dung lượng S3 vượt quá 5GB');
            }, 900000);
            // 15 phut

            setIntervalId(interval);
        } else
        {
            if (intervalId)
            {
                clearInterval(intervalId);
            }
        }

        return () =>
        {
            if (intervalId)
            {
                clearInterval(intervalId);
            }
        };
    }, [showStorageWarning]);

    const onCheckLogin = async () =>
    {
        const request = await AxiosClient.get<ApiResponse>(BASE_API_PATH + loadCheckLogin, { withCredentials: true });
        if (request.data.success)
        {
            await requestForToken(setDeviceToken);
            await loadApi();
            await onLoadInfoPay();
        }
        else
        {
            onLogOut();
        }
    };

    const loadTokenDevice = async () =>
    {
        if (deviceToken)
        {
            const params = {
                firebaseToken: deviceToken,
            };
            dispatch(authAction.loadDeviceToken(deviceToken));
            await AxiosClient.post<ApiResponse>(BASE_API_PATH + loadDeviceToken, params, { withCredentials: true });
        }
        else
        {
            dispatch(authAction.loadDeviceToken(''));
        }

    };


    onMessageListener()
        .then((payload: any) =>
        {
            console.log(payload);
        })
        .catch((err) => console.log('failed: ', err));

    const loadApi = async () =>
    {
        let infomationClinic: Clinic = {};
        let userAccountLogin: userAccountLogin = {};
        let statistics: Statistics = {
            examinedValue: 0,
            examinedDifferenceValue: 0,
            pendingValue: 0,
            pendingDifferenceValue: 0
        };

        // let infoPay: GetInfoPay | null = null;

        await Axios.all([
            await AxiosClient.get<ApiResponse<userAccountLogin>>(BASE_API_PATH + loadInfoUser, { withCredentials: true }),
            await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true }),
            await AxiosClient.get<ApiResponse<Statistics>>(BASE_API_PATH + getStatisticInSidebar, { withCredentials: true }),
        ]).then(axios.spread((resUserAccountLogin, resInfomation, resStatistic) =>
        {

            if (resInfomation.data.success)
            {
                if (resInfomation.data.results)
                {
                    dispatch(clinicAction.setShowStorageWarning(resInfomation.data.results?.isSizeWarning as unknown as boolean));
                }
                infomationClinic = resInfomation.data.results as unknown as Clinic;
            }
            if (resUserAccountLogin.data.success)
            {
                userAccountLogin = resUserAccountLogin.data.results as unknown as userAccountLogin;
            }
            if (resStatistic.data.success)
            {
                statistics = resStatistic.data.results as Statistics;
            }
            // if (resInfoPay.data.success)
            // {
            //     infoPay = resInfoPay.data.results as unknown as GetInfoPay;
            // }
            // else
            // {
            //     infoPay = null;
            // }

        }));
        setState({
            ...state,
            infoClinic: infomationClinic,
            fullName: userAccountLogin.fullName ? userAccountLogin.fullName : '',
            roles: userAccountLogin.roles ? userAccountLogin.roles : [],
            userName: userAccountLogin.userName ? userAccountLogin.userName : '',
            loading: false,
            statistics: statistics,
            // infoPay: infoPay
        });
    };

    const onLoadInfoPay = async () =>
    {
        const response = await AxiosClient.get<ApiResponse<GetInfoPay>>(BASE_API_PATH + getInfoPay, { withCredentials: true });
        if (response.data.success)
        {
            let infoPay: GetInfoPay | null = null;

            infoPay = response.data.results as unknown as GetInfoPay;
            setInfoPay(infoPay);

        }
    };
    useEffect(() =>
    {
        const contentShortText = document.getElementsByClassName('short-text-menu') as HTMLCollectionOf<HTMLElement>;
        const contentTextMenu = document.getElementsByClassName('text-menu') as HTMLCollectionOf<HTMLElement>;

        if (state.collapsed)
        {
            _.forEach(contentShortText, (item: HTMLElement) =>
            {
                item.style.display = 'block';
            });
            _.forEach(contentTextMenu, (item: HTMLElement) =>
            {
                item.style.display = 'none';
            });
        } else
        {
            _.forEach(contentShortText, (item: HTMLElement) =>
            {
                item.style.display = 'none';
            });
            _.forEach(contentTextMenu, (item: HTMLElement) =>
            {
                item.style.display = 'block';
            });
        }
    }, [state.collapsed]);

    const onChangePassword = () =>
    {
        modalRef.current?.onOpen(<ChangePasswordForm onCloseModal={onCloseModal} />, 'Đổi mật khẩu', 30);
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };


    const onChangeSiderBar = () =>
    {
        setState({
            ...state,
            collapsed: !state.collapsed
        });
    };

    const onChangeLeft = (info: MenuInfo) =>
    {
        setState({
            ...state,
            defaultSelectedKeysLeft: info.key,
            defaultSelectedKeysTop: '',
            locationState: info.key
        });
    };

    const onChangeTop = (info: MenuInfo) =>
    {

        setState({
            ...state,
            defaultSelectedKeysTop: info.key,
            defaultSelectedKeysLeft: '',
            locationState: info.key
        });
    };

    const onLogOut = () =>
    {
        dispatch(authAction.logout());
    };


    const onRenderStatistics = (statistics: Statistics) =>
    {
        return (
            <div className='flex mt-2 px-[25px]'>
                <div className='w-1/2'>
                    <div className={`flex items-center ${ statistics?.examinedDifferenceValue === 0 ? 'text-yellow-500' :
                        (statistics?.examinedDifferenceValue > 0 ? 'text-green-500' : 'text-red-500') }`}>
                        <p className='text-xl mb-0 mr-1'>{statistics?.examinedValue}</p>
                        <div className='flex flex-col items-start'>
                            <i className='fa fa-bar-chart text-md'></i>
                            <p className='m-0 text-sm'>
                                {statistics?.examinedDifferenceValue === 0 ? 'Không đổi' :
                                    (statistics?.examinedDifferenceValue > 0 ? `+ ${ statistics?.examinedDifferenceValue }` : `- ${ Math.abs(statistics?.examinedDifferenceValue) }`)}
                            </p>
                        </div>
                    </div>
                    <p className='mt-1 text-left text-xs'>Số bệnh nhân khám hôm qua</p>
                </div>
                <div className='w-1/2'>
                    <div className={`flex items-center ${ statistics?.pendingDifferenceValue === 0 ? 'text-yellow-500' :
                        (statistics?.pendingDifferenceValue > 0 ? 'text-green-500' : 'text-red-500') }`}>
                        <p className='text-xl mb-0 mr-1'>{statistics?.pendingValue}</p>
                        <div className='flex flex-col items-start'>
                            <i className='fa fa-bar-chart text-md'></i>
                            <p className='m-0 text-sm'>
                                {statistics?.pendingDifferenceValue === 0 ? 'Không đổi' :
                                    (statistics?.pendingDifferenceValue > 0 ? `+ ${ statistics?.pendingDifferenceValue }` : `- ${ Math.abs(statistics?.pendingDifferenceValue) }`)}
                            </p>
                        </div>
                    </div>
                    <p className='mt-1 text-left text-xs'>Số bệnh nhân chờ khám hôm nay</p>
                </div>
            </div>
        );
    };

    const onRenderSideBarSuper = () =>
    {
        return (
            <Menu
                theme='light'
                className='menu-custom'
                defaultSelectedKeys={[!state.defaultSelectedKeysLeft ? (location.pathname ? location.pathname : 'patient-record') : '']}
                onClick={(info) => onChangeLeft(info)}
                selectedKeys={[state.defaultSelectedKeysLeft ? state.defaultSelectedKeysLeft : state.locationState]}
                style={{ borderRight: 0 }}
            >
                <div className='title-menu'>
                    <img src='./function_icon.svg' />
                    <span className='text-title-menu short-text-menu'>CN</span>
                    <span className='text-title-menu text-menu'>
                        CHỨC NĂNG
                    </span>
                </div>
                {state.roles.indexOf('SUPER_ADMIN') !== -1 ?
                    <>
                        <Menu.Item key="/partner-management">
                            <Link to="/partner-management">
                                <span className='short-text-menu'>QLĐT</span>
                                <span className='text-menu'>Quản lý đối tác</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/patient-management">
                            <Link to="/patient-management">
                                <span className='text-menu'>Quản lý bệnh nhân</span>
                            </Link>
                        </Menu.Item>
                    </>

                    :
                    <>
                        <Menu.Item key="/patient-record">
                            <Link to="/patient-record">
                                <span className='short-text-menu'>HSBN</span>
                                <span className='text-menu'>1. Hồ sơ bệnh nhân</span>
                            </Link>
                        </Menu.Item>

                        {state.roles.indexOf('PARTNER') !== -1 ?
                            <Menu.Item key="/clinic">
                                <Link to="/clinic">
                                    <span className='short-text-menu'>PK</span>
                                    <span className='text-menu'>2. Phòng khám</span>
                                </Link>
                            </Menu.Item>
                            : <div className='d-none'></div>}
                        {state.roles.indexOf('CLINIC_ADMIN') !== -1 ?
                            <>
                                <div className='title-menu second-menu'>
                                    <img src='./vector_icon.svg' />
                                    <span className='short-text-menu text-title-menu '>HTCH</span>
                                    <span className='text-title-menu text-menu'>
                                        HỆ THỐNG CẤU HÌNH
                                    </span>
                                </div>
                                <Menu.Item key="/user-account-setting">
                                    <Link to="/user-account-setting">
                                        <span className='short-text-menu'>BSDN</span>
                                        <span className='text-menu'>1. Bác sĩ đăng nhập</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/designation-medical-exam">
                                    <Link to="/designation-medical-exam">
                                        <span className='short-text-menu'>CDK</span>
                                        <span className='text-menu'>
                                            2. Dịch vụ
                                        </span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/medical-exam-pictures">
                                    <Link to="/medical-exam-pictures">
                                        <span className='short-text-menu'>HAKB</span>
                                        <span className='text-menu'>
                                            3. Hình ảnh khám bệnh
                                        </span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/disease-analysis">
                                    <Link to="/disease-analysis">
                                        <span className='short-text-menu'>MTKQ</span>
                                        <span className='text-menu'>
                                            4. Mô tả KQ/ Phân tích bệnh
                                        </span>
                                    </Link >
                                </Menu.Item >
                                <Menu.Item key="/medicine-group">
                                    <Link to="/medicine-group">
                                        <span className='short-text-menu'>NT</span>
                                        <span className='text-menu'>
                                            5. Nhóm thuốc
                                        </span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/medicine">
                                    <Link to="/medicine">
                                        <span className='short-text-menu'>Thuốc</span>
                                        <span className='text-menu'>
                                            6. Thuốc
                                        </span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/auto-send-notification">
                                    <Link to="/auto-send-notification">
                                        <span className='short-text-menu'>TĐGTB</span>
                                        <span className='text-menu'>
                                            7. Cấu hình tự động
                                        </span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/infomation">
                                    <Link to="/infomation">
                                        <span className='short-text-menu'>TTPK</span>
                                        <span className='text-menu'>
                                            8. Thông tin phòng khám
                                        </span>
                                    </Link>
                                </Menu.Item>

                            </> : <div></div>}
                    </>
                }
                <Button className='btn-logout' onClick={onLogOut}>
                    Đăng xuất
                </Button>
            </Menu>
        );
    };

    return (
        <div className='body'>
            <Router>
                <QueryClientProvider client={queryClient}>
                    <Layout>
                        <Header>
                            <HeaderADV roles={state.roles}
                                modalRef={modalRef}
                                infoPayment={infoPay} />
                        </Header>
                        <Layout>
                            <Sider className="site-layout-background"
                                theme="light"
                                width={260}
                                trigger={null}
                                collapsible
                                collapsed={state.collapsed}>
                                <div className='side-bar-menu'>
                                    <div className='border-avt-circle'></div>
                                    <div className='container-user-info'>
                                        <div className='border-avt-circle'>
                                            <Avatar size={84} src={'/avt-doctor.png'} />
                                        </div>
                                        <div className='text-info'>
                                            <div className="name-doctor text-menu">
                                                Tài khoản : {state.userName}
                                            </div>
                                            <div className="hello-doctor text-menu">
                                                {/* {t('HelloDoctor')} */}
                                                Xin chào bác sĩ
                                            </div>
                                            <div className="name-doctor text-menu">
                                                {state.fullName}
                                            </div>
                                            <div className="good-luck text-menu">
                                                Chúc bạn một ngày làm việc hiệu quả
                                            </div>
                                            <a className="change-password text-menu" onClick={() => { onChangePassword(); }}>
                                                Đổi mật khẩu
                                            </a>
                                        </div>
                                        <div className="line-draw"></div>

                                        {onRenderStatistics(state.statistics)}

                                        <div className="line-draw"></div>
                                    </div>

                                    {onRenderSideBarSuper()}

                                </div >
                            </Sider >
                            <Layout className='px-2'>
                                <div className='top-header-menu'>
                                    <div className='controll-sider' onClick={() => onChangeSiderBar()}>
                                        {
                                            state.collapsed
                                                ? <img src='show_sider.svg' style={{ width: 50, height: 50 }} />
                                                : <img src='unshow_sider.svg' style={{ width: 50, height: 50 }} />
                                        }
                                    </div>
                                    {state.roles.indexOf('SUPER_ADMIN') === -1 ?
                                        <Header className='header-custom'>
                                            <Menu theme='light'
                                                className='menu-header-custom'
                                                onClick={(menu) => onChangeTop(menu)}
                                                defaultSelectedKeys={[!state.defaultSelectedKeysTop ? (location.pathname ? location.pathname : 'patient-record') : '']}
                                                selectedKeys={[state.defaultSelectedKeysTop ? state.defaultSelectedKeysTop : state.locationState]}
                                                style={{ borderRight: 0, marginTop: 5, marginBottom: 5 }}>
                                                <Menu.Item key="/patient-record">
                                                    <Link to="/patient-record">
                                                        KHÁM BỆNH
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/patient-waiting">
                                                    <Link to="/patient-waiting">
                                                        ĐẶT KHÁM
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/messenge">
                                                    <Link to="/message">
                                                        TIN NHẮN
                                                    </Link>

                                                </Menu.Item>
                                                <Menu.Item key="/patient-care">
                                                    <Link to="/patient-care">
                                                        CHĂM SÓC BỆNH NHÂN
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/statistics">
                                                    <Link to="/statistics">
                                                        THỐNG KÊ
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/service">
                                                    <Link to="/service">
                                                        DỊCH VỤ CHO PHÒNG KHÁM
                                                    </Link>
                                                </Menu.Item>
                                            </Menu>
                                        </Header>
                                        :
                                        <Header className='header-custom'>
                                            <Menu theme='light'
                                                className='menu-header-custom'
                                                onClick={(menu) => onChangeTop(menu)}
                                                defaultSelectedKeys={[!state.defaultSelectedKeysTop ? (location.pathname ? location.pathname : 'clinic') : '']}
                                                selectedKeys={[state.defaultSelectedKeysTop ? state.defaultSelectedKeysTop : state.locationState]}
                                                style={{ borderRight: 0 }}>
                                                <Menu.Item key="/clinic">
                                                    <Link to="/clinic">
                                                        PHÒNG KHÁM
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/visits">
                                                    <Link to="/visits">
                                                        THỐNG KÊ LƯỢT KHÁM
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/statistics-admin">
                                                    <Link to="/statistics-admin">
                                                        THỐNG KÊ
                                                    </Link>
                                                </Menu.Item>
                                                {/* <Menu.Item key="/setting">
                                                    <Link to="/message">
                                                        CÀI ĐẶT
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/report">
                                                    <Link to="/patient-care">
                                                        BÁO CÁO
                                                    </Link>
                                                </Menu.Item> */}
                                            </Menu>
                                        </Header>
                                    }

                                </div>
                                <Content style={{ overflow: 'hidden', marginTop: 70, backgroundColor: '#fffff' }}>
                                    <div className="site-layout-background content-body" >
                                        {state.roles.indexOf('SUPER_ADMIN') != -1 ?
                                            <Switch>
                                                <Route path={'/clinic'}>
                                                    <ClinicView />
                                                </Route>
                                                <Route path={'/visits'}>
                                                    <ClinicVisitsView />
                                                </Route>
                                                <Route path={'/statistics-admin'}>
                                                    <StatisticsDashboardAdminView />
                                                </Route>
                                                <Route path={'/partner-management'}>
                                                    <PartnerList />
                                                </Route>
                                                <Route path={'/patient-management'}>
                                                    <PatientList />
                                                </Route>
                                                <Route path={'/medical-examination-history/:patientId'}>
                                                    <ExaminationHistory />
                                                </Route>
                                            </Switch>
                                            :
                                            <Switch>
                                                {state.roles.includes('PARTNER') && <Route path={'/clinic'}>
                                                    <ClinicView role={'PARTNER'} />
                                                </Route>}
                                                <Route path={'/medical-examination'}>
                                                    <MedicalExaminationView />
                                                </Route>
                                                <Route path={'/medical-exam-template'}>
                                                    <MedicalExamTemplateView />
                                                </Route>
                                                <Route path={'/designation-medical-exam'}>
                                                    <DesignationMedicalExamView />
                                                </Route>
                                                <Route path={'/medical-exam-pictures'}>
                                                    <MedicalExamPicturesView />
                                                </Route>

                                                <Route path={'/patient-care'}>
                                                    <PatientCareView />
                                                </Route>
                                                <Route path={'/patient-waiting'}>
                                                    <PatientWaitingView />
                                                </Route>
                                                <Route path={'/message'}>
                                                    <MessageView />
                                                </Route>
                                                <Route path={'/statistics'}>
                                                    <StatisticsDashboardView />
                                                </Route>
                                                <Route path={'/patient-record'}>
                                                    <PatientRecordView onLoadInfoPay={onLoadInfoPay} clinicId={state.infoClinic.id as number} />
                                                </Route>
                                                <Route path={'/service'}>
                                                    <ClinicServiceView />
                                                </Route>
                                                {state.roles.indexOf('CLINIC_ADMIN') != -1 ? <div className='h-full w-full'>
                                                    <Route path={'/user-account-setting'}>
                                                        <UserAccountSettingView />
                                                    </Route>
                                                    <Route path={'/designation-medical-exam'}>
                                                        <DesignationMedicalExamView />
                                                    </Route>
                                                    <Route path={'/disease-analysis'}>
                                                        <DiseaseAnalysisView />
                                                    </Route>
                                                    <Route path={'/medicine-group'}>
                                                        <MedicineGroupView />
                                                    </Route>
                                                    <Route path={'/medicine'}>
                                                        <MedicineView />
                                                    </Route>
                                                    <Route path={'/auto-send-notification'}>
                                                        <AutoSendNotificationView />
                                                    </Route>
                                                    <Route path={'/infomation'}>
                                                        {/* <InfomationView /> */}
                                                        <ClinicSettingView />
                                                    </Route>
                                                </div>
                                                    : <div></div>
                                                }
                                            </Switch>
                                        }

                                    </div>
                                </Content>
                            </Layout>

                        </Layout >
                    </Layout >
                </QueryClientProvider>
            </Router >
            <CustomModal ref={modalRef} />

        </div >
    );
};
export default LayoutApp;