import { ColDef, ICellRendererParams } from 'ag-grid-community';



export interface HistoryPaymentDto
{
    amount?: string;
    amountPay?: string;
    bankCode?: number;
    status: number;
    bankTransNo?: string;
    orderDescription?: string;
    createdAt?: string;
}

export const HistoryPaymentColumns: ColDef[] = [
    {
        headerName: 'Mã giao dịch',
        field: 'orderCode',
        tooltipField: 'orderCode',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
    ,
    {
        headerName: 'Số điểm',
        field: 'amountPay',
        tooltipField: 'amountPay',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        minWidth: 100,
    }
    ,

    {
        headerName: 'Nội dung chuyển khoản',
        field: 'orderDescription',
        tooltipField: 'orderDescription',
        headerClass: 'text-center',
        minWidth: 100,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Thời gian',
        field: 'createdAt',
        tooltipField: 'createdAt',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        maxWidth: 150,
    }
];


