import { ColDef } from 'ag-grid-community';



export interface HistoryDeductDto
{
    action?: string;
    amountPay?: number;
    status?: string;
    description?: string;
    createdAt?: string;
}

export const HistoryDeductColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 80,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
    },
    {
        headerName: 'Trạng thái',
        field: 'status',
        tooltipField: 'status',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        maxWidth: 200,
    }
    ,
    {
        headerName: 'Số điểm',
        field: 'amountPay',
        tooltipField: 'amountPay',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        maxWidth: 180,
    }
    ,

    {
        headerName: 'Mô tả',
        field: 'description',
        tooltipField: 'description',
        headerClass: 'text-center',
        minWidth: 200,
        cellStyle: { 'text-align': 'center' },
    }
    ,
    {
        headerName: 'Thời gian',
        field: 'createdAt',
        tooltipField: 'createdAt',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
        maxWidth: 150,
    }
];


