import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Popconfirm } from 'antd';
import React from 'react';

import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import { CANCEL, CONFIRM_TO_DELETE, SUCCESS } from '../../../constants/MessageConstants';
import { MedicalExamTemplate } from '../../../types/MedicalExamTemplate/MedicalExamTemplate';
import { MedicalExamTemplateGridColumns } from '../../../types/MedicalExamTemplate/MedicalExamTemplateSetting';

interface IGridMedicalExamTemplate
{
    record: MedicalExamTemplate[],
    onEdit: (value: MedicalExamTemplate) => void;
    onDelete: (value: MedicalExamTemplate) => void;
    tableName: string;
}

const GridDesignationMedicalExam = (props: IGridMedicalExamTemplate) =>
{
    const { onEdit, onDelete } = props;
    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...MedicalExamTemplateGridColumns,
            {
                headerName: 'Loại dịch vụ',
                field: 'serviceType',
                width: 150,
                headerClass: 'text-center',
                tooltipField: 'serviceType',
                cellRenderer: (params: any) =>
                {
                    const data = params.data.groupDesignation;
                    return (
                        <div className="flex items-center justify-center">
                            {data === 0 ? 'Dịch vụ chính' : 'Dịch vụ bổ sung'}
                        </div>
                    );
                },
                sortable: false,
                filter: false
            },
            {
                headerName: 'Loại chỉ định',
                field: 'designationType',
                width: 150,
                headerClass: 'text-center',
                tooltipField: 'serviceType',
                sortable: false,
                filter: false
            },
            {
                headerName: 'Đơn giá',
                field: 'designationAmount',
                minWidth: 120,
                maxWidth: 120,
                headerClass: 'text-center',
                tooltipField: 'designationAmount',
                cellRenderer: (params: any) =>
                {
                    const data = params.data.designationAmount;
                    function formatCurrency(value: number)
                    {
                        const formatter = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' });
                        const formattedValue = formatter.format(value).replace(/\u00A0/g, ' ');
                        return formattedValue;
                    }
                    return (
                        <div className="flex items-center justify-end">
                            {formatCurrency(data)}
                        </div>
                    );
                },
            },
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                width: 150,
                maxWidth: 200,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams<MedicalExamTemplate>) =>
                {
                    const data = params.data as MedicalExamTemplate;
                    return (
                        <div className="flex items-center justify-center">
                            <GridButtonBase type={'edit'} onClick={() => { onEdit(data); }} />
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { onDelete(data); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };
    return (
        <div className='mt-2 mb-6' style={{ height: 'calc(70vh)' }}>
            <h1 className=' text-lg font-bold text-[#2c999c]  ml-3'>{props.tableName}</h1>
            <BaseGrid
                className="ag-theme-alpine"
                rowSelection={'multiple'}
                rowGroupPanelShow={'always'}
                pivotPanelShow={'always'}
                animateRows={true}
                columnDefs={getColumnConfig()}
                pagination={true}
                suppressClickEdit={true}
                rowData={props.record}
            />
        </div>
    );
};

export default GridDesignationMedicalExam; 