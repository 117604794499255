import { ColDef } from 'ag-grid-community';



export const PatientRecordGridColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 60,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        minWidth: 150,
        tooltipField: 'fullName',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Năm sinh',
        field: 'yearOfBirth',
        tooltipField: 'yearOfBirth',
        headerClass: 'text-center',
        maxWidth: 90,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Điện thoại',
        field: 'phone',
        tooltipField: 'phone',
        headerClass: 'text-center',
        minWidth: 70,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Giới tính',
        field: 'genderLabel',
        tooltipField: 'genderLabel',
        headerClass: 'text-center',
        maxWidth: 90,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Địa chỉ bệnh nhân',
        field: 'location',
        tooltipField: 'location',
        headerClass: 'text-center',
        minWidth: 120,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Lý do khám',
        field: 'reason',
        tooltipField: 'reason',
        headerClass: 'text-center',
        minWidth: 250,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Thời gian khám',
        field: 'datePatient',
        tooltipField: 'datePatient',
        headerClass: 'text-center',
        minWidth: 150,
        cellStyle: { 'text-align': 'start' }
    }
];

export const PatientCareGridColumns: ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        field: 'stt',
        maxWidth: 60,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' },
    },
    {
        headerName: '#',
        field: 'select',
        maxWidth: 60,
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'center' },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        minWidth: 150,
        tooltipField: 'fullName',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Năm sinh',
        field: 'yearOfBirth',
        tooltipField: 'yearOfBirth',
        headerClass: 'text-center',
        maxWidth: 90,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        tooltipField: 'phone',
        headerClass: 'text-center',
        minWidth: 120,
        maxWidth: 120,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Giới tính',
        field: 'gender',
        tooltipField: 'gender',
        headerClass: 'text-center',
        maxWidth: 90,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Địa chỉ bệnh nhân',
        field: 'location',
        tooltipField: 'location',
        headerClass: 'text-center',
        minWidth: 150,
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Lý do khám',
        field: 'reason',
        tooltipField: 'reason',
        headerClass: 'text-center',
        minWidth: 180,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Chỉ định khám',
        field: 'designationMedicalExamName',
        tooltipField: 'designationMedicalExamName',
        headerClass: 'text-center',
        minWidth: 200,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Hẹn tái khám',
        field: 'reExamDateTime',
        tooltipField: 'reExamDateTime',
        headerClass: 'text-center',
        minWidth: 120,
        maxWidth: 120,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Ngày khám',
        field: 'datePatient',
        tooltipField: 'datePatient',
        headerClass: 'text-center',
        minWidth: 150,
        cellStyle: { 'text-align': 'start' }
    }
];