import { ColDef } from 'ag-grid-community';


	
export const UserAccountSettingGridColumns :ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        maxWidth: 80,
        headerClass:'text-center',
        cellStyle: { textAlign: 'center' },
		
    },
    {
        headerName: 'Họ và tên',
        field: 'fullName',
        minWidth: 200,
        headerClass:'text-center',
        tooltipField:'fullName',
        cellStyle: { textAlign: 'start' }
    },
    {
        headerName: 'Email',
        field: 'email',
        minWidth: 230,
        headerClass:'text-center',
        tooltipField:'email',
        cellStyle: { textAlign: 'start' }
    },
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        maxWidth: 120,
        headerClass:'text-center',
        tooltipField:'phone',
        cellStyle: { textAlign: 'start' }
    },
    {
        headerName: 'Tên đăng nhập',
        field: 'userName',
        tooltipField:'userName',
        headerClass:'text-center',
        cellStyle: { textAlign: 'start' }
    },
];