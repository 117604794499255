import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../style/layout-header.scss';

import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Popconfirm } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import AxiosClient, { Axios } from '../../../apis/api/axiosClient';
import BaseGrid from '../../../components/BaseGrid/BaseGrid';
import GridButtonBase from '../../../components/ButtonBase/GridButtonBase';
import CustomModal, { ModalRef } from '../../../components/CustomModal/CustomModal';
import { BaseFormRef } from '../../../components/FormFields/BaseForm';
import { IComboBox } from '../../../components/FormFields/ComboBox';
import Overlay, { OverlayRef } from '../../../components/Overlay/Overlay';
import { CANCEL, CONFIRM_TO_DELETE, SUCCESS } from '../../../constants/MessageConstants';
import { useAppDispatch } from '../../../hooks/hook';
import useMergeState from '../../../hooks/useMergeState';
import NotificationForm from '../../../module/Notification/NotificationForm';
import PatientCareUpdate from '../../../module/PatientCare/PatientCareUpdate';
import { ApiResponse } from '../../../types/api.type';
import { Clinic } from '../../../types/Clinic';
import { MedicalExaminationFilterDto } from '../../../types/MedicalExamination/MedicalExaminationFilterDto';
import { MedicalExaminationGridDto } from '../../../types/MedicalExamination/MedicalExaminationGridDto';
import { NotificationPush } from '../../../types/NotificationWeb/NotificationPush';
import { PatientRecord } from '../../../types/PatientRecord/PatientRecord';
import { PatientCareGridColumns } from '../../../types/PatientRecord/PatientRecordSetting';
import { ApiUtil, BASE_API_PATH } from '../../../utils/ApiUtil';
import { comboDistrictPatientCare, comboProvincePatientCare, loadInfomationClinic } from '../../Infomation/api/constants';
import { clinicAction } from '../../Infomation/ClinicSlice';
import { deleteMedicalExamination, indexMedicalExamination, pushNoti, sendSms, updateMedicalExamination } from '../api/constants';
import HeaderFilterPatientCare from './HeaderFilterPatientCare';
import PatientSendSmsHeader from './PatientSendSmsHeader';

interface IState
{
    medicalExamination: MedicalExaminationGridDto[];
    optionsProvince: IComboBox[];
    loading?: boolean;
    infomationClinic: Clinic;
}
const gridOptions: GridOptions<MedicalExaminationGridDto> = {};

const PatientCareView = () =>
{
    const dispatch = useAppDispatch();
    const overlayRef = useRef<OverlayRef>(null);
    const modalRef = useRef<ModalRef>(null);
    const [optionsDistrict, setOptionsDistrict] = useState<IComboBox[]>([]);
    const formRefFilter = useRef<BaseFormRef>(null);
    const [state, setState] = useMergeState<IState>({
        medicalExamination: [],
        optionsProvince: [],
        loading: true,
        infomationClinic: {}
    });

    const [countSelected, setCountSelected] = useState<number>(0);

    useEffect(() =>
    {
        loadApi();
    }, []);


    useEffect(() =>
    {
        onUpdateSelected();
    }, [state.medicalExamination]);

    const loadApi = async () =>
    {
        const params: MedicalExaminationFilterDto = {};
        overlayRef.current?.open();

        Axios.all([
            await AxiosClient.get<ApiResponse>(BASE_API_PATH + comboProvincePatientCare),
            await AxiosClient.post<ApiResponse<MedicalExaminationGridDto>>(BASE_API_PATH + indexMedicalExamination, params, { withCredentials: true }),
            await AxiosClient.get<ApiResponse<any>>(BASE_API_PATH + loadInfomationClinic, { withCredentials: true })
        ]).then(axios.spread((resComboProvince, resMedicalExaminationGrid, resInfomation) =>
        {
            let optionsProvince: IComboBox[] = [];
            let medicalExaminationGridDto: MedicalExaminationGridDto[] = [];
            let infomationClinic: Clinic = {};

            if (resComboProvince.data.success)
            {
                optionsProvince = resComboProvince.data.results as unknown as IComboBox[];
            }
            if (resMedicalExaminationGrid.data.success)
            {
                medicalExaminationGridDto = resMedicalExaminationGrid.data.results as unknown as MedicalExaminationGridDto[];
            }
            if (resInfomation.data.success)
            {
                if (resInfomation.data.results)
                {
                    dispatch(clinicAction.setShowStorageWarning(resInfomation.data.results.isSizeWarning || false));
                }
                infomationClinic = resInfomation.data.results as unknown as Clinic;
            }
            setState({
                optionsProvince: optionsProvince,
                medicalExamination: medicalExaminationGridDto,
                infomationClinic: infomationClinic
            });
            overlayRef.current?.close();

        }));
    };

    const loadOptionsDistrict = async (value: string) =>
    {
        if (value !== 'ALL')
        {
            const response = await AxiosClient.get<ApiResponse<IComboBox>>(BASE_API_PATH + comboDistrictPatientCare + value);
            if (response.data.success)
            {
                const data = response.data.results as unknown as IComboBox[];
                setOptionsDistrict(data);
            }
        }
        else
        {
            setOptionsDistrict([]);

        }

    };


    const onLoadDataGrid = async (value: MedicalExaminationFilterDto) =>
    {
        overlayRef.current?.open();
        const params: MedicalExaminationFilterDto = {
            ...value,
            filterByDateFrom: value?.timeRange ? value?.timeRange[0].format('DD/MM/YYYY') : null,
            filterByDateTo: value?.timeRange ? value?.timeRange[1].format('DD/MM/YYYY') : null
        };
        const response = await AxiosClient.post<ApiResponse<MedicalExaminationGridDto>>(BASE_API_PATH + indexMedicalExamination, params, { withCredentials: true });
        if (response.data.success)
        {
            setState({
                loading: false,
                medicalExamination: response.data.results as MedicalExaminationGridDto[]
            });
        }
        overlayRef.current?.close();

    };
    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };
    const onOpenUpdate = (value: PatientRecord) =>
    {
        const defaultValue: PatientRecord = {
            ...value,
            reExamDateTime: value.reExamDateTime ? moment(value.reExamDateTime, 'DD/MM/YYYY') : '',
            yearOfBirth: moment(value.yearOfBirth, 'YYYY')
        };
        modalRef.current?.onOpen(<PatientCareUpdate defaultvalue={defaultValue}
            handleSubmit={onUpdatePatient}
            onClose={onCloseModal} />, 'Sửa thông tin', 40);
    };

    const onUpdatePatient = async (value: PatientRecord) =>
    {
        const params: PatientRecord = {
            ...value,
            reExamDateTime: value.reExamDateTime ? (value.reExamDateTime as moment.Moment).format('DD/MM/YYYY').toString() : null,
            yearOfBirth: value.yearOfBirth ? (value.yearOfBirth as moment.Moment).format('YYYY').toString() : null
        };
        const response = await AxiosClient.post<ApiResponse<MedicalExaminationGridDto>>(BASE_API_PATH + updateMedicalExamination, params, { withCredentials: true });
        if (response.data.success)
        {
            onLoadDataGrid(formRefFilter.current?.getValues() as MedicalExaminationFilterDto);
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        onCloseModal();
    };
    const onHandleDelete = async (value: number) =>
    {
        const response = await AxiosClient.post<ApiResponse<MedicalExaminationGridDto>>(BASE_API_PATH + deleteMedicalExamination + value);
        if (response.data.success)
        {
            onLoadDataGrid(formRefFilter.current?.getValues() as MedicalExaminationFilterDto);
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
        onCloseModal();
    };


    const getColumnConfig = (): ColDef[] =>
    {
        return [
            ...PatientCareGridColumns,
            {
                headerName: 'Hành động',
                field: 'action',
                headerClass: 'text-center',
                minWidth: 150,
                maxWidth: 200,
                // pinned: 'right',
                cellStyle: { 'text-align': 'start' },

                cellRenderer: (params: ICellRendererParams<PatientRecord>) =>
                {
                    const data = params.data as PatientRecord;
                    return (
                        <div className="flex items-center justify-center" style={{ height: '120%' }}>
                            <GridButtonBase type={'edit'} onClick={() => { onOpenUpdate(data); }} />
                            <Popconfirm
                                placement="topRight"
                                title={CONFIRM_TO_DELETE}
                                onConfirm={() => { onHandleDelete(data.id as number); }}
                                okText={SUCCESS}
                                cancelText={CANCEL}
                            >
                                <GridButtonBase
                                    type={'delete'}
                                    onClick={() =>
                                    {
                                    }}
                                />
                            </Popconfirm>

                        </div>
                    );
                },
            },

        ];
    };

    const onSendMultiSms = async (content: string) =>
    {
        if (!content) return ApiUtil.ToastError('Vui lòng nhập thông tin tin nhắn!');
        const diseaseAnalysisDetail = gridOptions.api?.getSelectedRows() as MedicalExaminationGridDto[];
        if (diseaseAnalysisDetail?.length === 0) return ApiUtil.ToastError('Vui lòng chọn ít nhất 1 bệnh nhân để gửi thông báo!');
        const phoneNotiArr: string[] = [];
        diseaseAnalysisDetail?.map(item =>
        {
            if (item.phone && !phoneNotiArr.includes(item.phone))
            {
                phoneNotiArr.push(item.phone);
            }
        });
        const phoneListParams = phoneNotiArr.join();
        const params = {
            phoneListString: phoneListParams,
            content: content
        };
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + sendSms, params, { withCredentials: true });
        if (response.data.success)
        {
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onSendMultiNotiModal = async (content: string) =>
    {
        if (!content) return ApiUtil.ToastError('Vui lòng nhập thông tin thông báo!');
        const diseaseAnalysisDetail = gridOptions.api?.getSelectedRows() as MedicalExaminationGridDto[];
        const phoneNotiArr: string[] = [];
        diseaseAnalysisDetail?.map(item =>
        {
            if (item.phone && !phoneNotiArr.includes(item.phone))
            {
                phoneNotiArr.push(item.phone);
            }
        });
        if (phoneNotiArr?.length === 0) return ApiUtil.ToastError('Vui lòng chọn ít nhất 1 bệnh nhân để gửi thông báo!');
        modalRef.current?.onOpen(<NotificationForm phoneArr={phoneNotiArr}
            content={content}
            onClose={onCloseModal}
            nameClinic={state.infomationClinic.name as string}
            handleSubmit={onSendMultiNoti} />, 'Tên thông báo', 40);
    };


    const onSendMultiNoti = async (title: string, phoneNotiArr: string[], content: string) =>
    {
        const params: NotificationPush = {
            title: title,
            content: content,
            phoneNotiArr: phoneNotiArr
        };
        const response = await AxiosClient.post<ApiResponse>(BASE_API_PATH + pushNoti, params);
        if (response.data.success)
        {
            ApiUtil.ToastSuccess(response.data.message);
        }
        else
        {
            ApiUtil.ToastError(response.data.message);
        }
    };

    const onUpdateSelected = () =>
    {
        const diseaseAnalysisDetail = gridOptions.api?.getSelectedRows() as MedicalExaminationGridDto[];
        if (!diseaseAnalysisDetail)
        {
            return;
        }
        setCountSelected(diseaseAnalysisDetail?.length);

    };

    const renderGrid = () =>
    {
        return <div className='' style={{ marginTop: -14, height: '67%' }}>

            <BaseGrid
                gridOptions={gridOptions}
                // className="ag-theme-alpine"
                rowSelection={'multiple'}
                rowGroupPanelShow={'always'}
                pivotPanelShow={'always'}
                columnDefs={getColumnConfig()}
                suppressClickEdit={true}
                suppressRowClickSelection={true}
                onRowSelected={(value) => { onUpdateSelected(); }}
                pagination={true}
                rowData={state.medicalExamination}
            />

        </div>;
    };


    return (
        <div
            className="content-view-patient-care overflow-y-hidden"
        >
            <div className='content-body-header overflow-y-hidden'>
                <PatientSendSmsHeader countSelected={countSelected}
                    onSendMultiSms={onSendMultiSms}
                    onSendMultiNoti={onSendMultiNotiModal} />
            </div>
            <div className='body-patient-care overflow-y-hidden'>
                <HeaderFilterPatientCare formRefFilter={formRefFilter}
                    onLoadDataGrid={onLoadDataGrid}
                    loadOptionsDistrict={loadOptionsDistrict}
                    optionsProvince={state.optionsProvince}
                    optionsDistrict={optionsDistrict}
                    onFilter={() => { }} />
                {renderGrid()}

            </div>
            <Overlay ref={overlayRef} />
            <CustomModal ref={modalRef} />
        </div>

    );
};

export default PatientCareView;