import { ColDef } from 'ag-grid-community';


export const VitalSignRecordSetting: ColDef[] = [
    {
        headerName: 'Nhịp tim',
        field: 'beat',
        tooltipField: 'beat',
        headerClass: 'text-center',
        cellStyle: { 'text-align': 'start' }
    },
    {
        headerName: 'Năm sinh',
        field: 'yearOfBirth',
        tooltipField: 'yearOfBirth',
        headerClass: 'text-center',
        maxWidth: 100,
        minWidth: 100,
        cellStyle: { 'text-align': 'center' }
    }
    ,
    {
        headerName: 'Số điện thoại',
        field: 'phone',
        tooltipField: 'phone',
        headerClass: 'text-center',
        maxWidth: 120,
        minWidth: 120,
        cellStyle: { 'text-align': 'start' }
    }
    ,
    {
        headerName: 'Giới tính',
        field: 'genderLabel',
        tooltipField: 'genderLabel',
        headerClass: 'text-center',
        maxWidth: 100,
        minWidth: 100,
        cellStyle: { 'text-align': 'center' }
    }
    ,
    {
        headerName: 'Thời gian khám',
        field: 'datePatient',
        tooltipField: 'datePatient',
        headerClass: 'text-center',
        maxWidth: 150,
        minWidth: 150,
        cellStyle: { 'text-align': 'center' },
    },
];
