import { ColDef } from 'ag-grid-community';

	
export const MedicineGroupSettingGridColumns :ColDef[] = [
    {
        headerName: 'STT',
        valueGetter: 'node.rowIndex + 1',
        headerClass:'text-center',
        cellStyle: { textAlign: 'center' },
        maxWidth: 80,

    },
    {
        headerName: 'Tên Nhóm Thuốc',
        headerClass:'text-center',
        field: 'name',
        width: 150,
        cellStyle: { 'text-align': 'start' }
    }

];