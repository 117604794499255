
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Tag } from 'antd';
import { FunctionComponent } from 'react';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { HistoryPaymentColumns, HistoryPaymentDto } from '../../types/PaymentClinic/HistoryPayment';

interface IHistoryPaymentFormProps
{
    onClose: () => void,
    defaultvalue?: HistoryPaymentDto[]
}

const HistoryPaymentForm: FunctionComponent<IHistoryPaymentFormProps> = (props) =>
{
    const { onClose, defaultvalue } = props;
    const getColumnConfig = (): ColDef[] =>
    {

        return [
            {
                headerName: 'STT',
                valueGetter: 'node.rowIndex + 1',
                field: 'stt',
                maxWidth: 80,
                headerClass: 'text-center',
                cellStyle: { 'text-align': 'start' },
            },
            {
                headerName: 'Trạng thái',
                field: 'status',
                tooltipField: 'status',
                headerClass: 'text-center',
                cellStyle: { 'text-align': 'center' },
                maxWidth: 150,
                cellRenderer: (params: ICellRendererParams<HistoryPaymentDto>) =>
                {
                    const data = params.data as HistoryPaymentDto;

                    if (data?.status === 0)
                    {
                        return (<Tag color="warning">
                            Chưa thanh toán
                        </Tag>);
                    }

                    if (data?.status === 2)
                    {
                        return (<Tag color="error">
                            Thất bại
                        </Tag>);
                    }

                    return (<Tag color="success">
                        Thành công
                    </Tag>);
                }
            },
            ...HistoryPaymentColumns,
        ];
    };


    return (
        <div>
            <div style={{ height: '500px' }}>
                <div className='mt-2' style={{ height: '100%' }}>
                    <BaseGrid
                        className="ag-theme-alpine"
                        rowSelection={'multiple'}
                        rowGroupPanelShow={'always'}
                        pivotPanelShow={'always'}
                        columnDefs={getColumnConfig()}
                        pagination={true}
                        rowData={defaultvalue ? defaultvalue : []}
                    />
                </div>
            </div>
            <div className="footer flex justify-center items-center mb-2">
                <ButtonBase style={{ width: 154, height: 40, justifyContent: 'center' }}
                    buttonName={'ĐÓNG'}
                    onClick={() => { onClose(); }}
                    buttonType="close"
                />
            </div>
        </div>
    );
};
export default HistoryPaymentForm;



