import { Slider } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import ButtonBase from '../../../components/ButtonBase/ButtonBase';
import { ModalRef } from '../../../components/CustomModal/CustomModal';
import CustomModal from '../../../components/CustomModal/CustomModal';
import ComboBox from '../../../components/FormFields/ComboBox';



interface IHeaderProps
{
    webcamRef?: React.RefObject<any>;
    imageAdjustment: IImageAdjustment;
    setImageAdjustment: React.Dispatch<React.SetStateAction<IImageAdjustment>>;
    deviceId?: string
}

interface IImageAdjustment 
{
    brightness: number,
    contrast: number,
    hue: number;
    saturation: number;
}

interface AdjustImageSettingProps extends IImageAdjustment
{
    onCloseModal: () => void;
    setImageAdjustment: React.Dispatch<React.SetStateAction<IImageAdjustment>>
}

const resolutionValue = {
    '1': {width: 1920, height: 1080},
    '2': {width: 1280, height: 720},
    '3': {width: 1024, height: 768},
    '4': {width: 800, height: 600},
    '5': {width: 720, height: 480},
    '6': {width: 720, height: 576},
    '7': {width: 640, height: 480},
    '8': {width: 320, height: 240},
};

const resolutionOptions = [
    {
        label: '1920 x 1080',
        value: '1',
    },
    {
        label: '1280 x 720',
        value: '2',

    },
    {
        label: '1024 x 768',
        value: '3',
    },
    {
        label: '800 x 600',
        value: '4',
    },
    {
        label: '720 x 480',
        value: '5',
    },
    {
        label: '720 x 576',
        value: '6',
    },
    {
        label: '640 x 480',
        value: '7',
    },
    {
        label: '320 x 240',
        value: '8',
    },

];

function AdjustImageSetting(props: AdjustImageSettingProps)
{
    const { onCloseModal, setImageAdjustment } = props;

    const [brightness, setBrightness] = useState<number>(props.brightness);
    const [hue, setHue] = useState<number>(props.hue);
    const [contrast, setContrast] = useState<number>(props.contrast);
    const [saturation, setSaturation] = useState<number>(props.saturation);

    return <div className='p-4 '>
        <div>
            <label className='font-bold'>Brightness:</label>
            <Slider value={brightness}
                min={1}
                max={200}
                onChange={setBrightness} />
        </div>
        <div>
            <label className='font-bold'>Contrast:</label>
            <Slider value={contrast}
                min={1}
                max={200}
                onChange={setContrast} />
        </div>
        <div>
            <label className='font-bold'>Hue:</label>
            <Slider value={hue}
                min={-360}
                max={360}
                onChange={setHue} />
        </div>
        <div>
            <label className='font-bold'>Saturation:</label>
            <Slider value={saturation}
                min={1}
                max={200}
                onChange={setSaturation} />
        </div>
        <div className='flex items-center justify-center mt-4 gap-x-4'>
            <ButtonBase buttonName={'Đặt lại'}
                onClick={() =>
                {
                    setBrightness(100);
                    setContrast(100);
                    setHue(0);
                    setSaturation(100);
                }} />
            <ButtonBase buttonName={'Cập nhật'}
                buttonType='save'
                onClick={() =>
                {
                    setImageAdjustment({ brightness, contrast, hue, saturation });
                    onCloseModal();
                }} />
        </div>
    </div>;
}

export default function WebcamCapture(props: IHeaderProps)
{
    const { deviceId, webcamRef, setImageAdjustment, imageAdjustment } = props;
    const [videoConstraints, setVideoConstraints] = useState<MediaTrackConstraints>({
        deviceId: deviceId,
        width: 1920,
        height: 1080
    });
    const modalRef = useRef<ModalRef>(null);

    const onOpen = () =>
    {
        modalRef.current?.onOpen(<AdjustImageSetting {...imageAdjustment}
            setImageAdjustment={setImageAdjustment}
            onCloseModal={onCloseModal} />, 'Chỉnh chất lượng hình ảnh', 30);
    };

    const onCloseModal = () =>
    {
        modalRef.current?.onClose();
    };

    useEffect(() =>
    {
        if (webcamRef)
        {

            const videoElement = webcamRef.current?.video;
            if (videoElement)
            {
                videoElement.style.filter = `brightness(${ imageAdjustment.brightness }%) contrast(${ imageAdjustment.contrast }%) hue-rotate(${ imageAdjustment.hue }deg) saturate(${ imageAdjustment.saturation }%)`;
            }
        }
    }, [webcamRef, imageAdjustment]);

    return (
        <div className="webcam-container h-full w-full">

            {deviceId ?
                (
                    <div>
                        <div className='mr-4'>

                            <ComboBox
                                onChange={(value: string) =>
                                {
                                    setVideoConstraints({
                                        ...videoConstraints,
                                        width: resolutionValue[value as keyof typeof resolutionValue].width,
                                        height: resolutionValue[value as keyof typeof resolutionValue].height
                                    });
                                    console.log({
                                        ...videoConstraints,
                                        width: resolutionValue[value as keyof typeof resolutionValue].width,
                                        height: resolutionValue[value as keyof typeof resolutionValue].height
                                    });
                                }}
                                comboValue={resolutionOptions}
                                name={'resolution'}
                                label={'Độ phân giải'} />
                            <ButtonBase
                                className='mx-auto mt-2'
                                onClick={() => onOpen()}
                                buttonName={'CHỈNH CHẤT LƯỢNG HÌNH ẢNH'}
                            />
                        </div>
                        <div className='mx-4' >

                            <div className=' flex flex-col my-2' style={{ height: '20px', font: 'Roboto', alignItems: 'center', fontWeight: 500, fontSize: '14px', lineHeight: '16px', color: '#2C999C' }}>
                                HÌNH ẢNH NỘI SOI TRỰC TIẾP
                            </div>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                screenshotQuality={1}
                            />
                        </div>
                    </div>
                ) : <div style={{ height: '100%' }}>
                </div>}
            <CustomModal ref={modalRef} />
        </div>

    );
}
